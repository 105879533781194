import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'
import {
  AllPhrasesReport,
  PhrasesWithEmptyResults,
  ReportTimeFrame,
  SearchReportApiRequestHelper,
} from '@/store/searchV3Report/searchReportApiRequestHelper'
import { AxiosResponse } from 'axios'
import Notifications from '@/store/notifications/notifications'
import apiRequest from '@/apiRequest'
import SearchReportsApiRequestPaths from '@/store/searchV3Report/searchReportApiRequestsPaths'

Vue.use(Vuex)

interface PhrasesCount {
  phrase: string;
  views: number;
}

interface PhrasesDetailsReport {
  searchId: string;
  emptyResults: {
    timeFrame: ReportTimeFrame;
    data: PhrasesCount[],
    totalPages: number,
    dataLoading: boolean,
    limit: number;
    offset: number;
    fileDataLoading: boolean;
    orderBy: string;
    sort: string;
  },
  allPhrases: {
    timeFrame: ReportTimeFrame;
    data: PhrasesCount[],
    totalPages: number,
    dataLoading: boolean,
    limit: number;
    offset: number;
    fileDataLoading: boolean;
    orderBy: string;
    sort: string;
  }
}

const getInitialState = (): PhrasesDetailsReport => ({
  searchId: '',
  emptyResults: {
    timeFrame: {
      start: moment().subtract(1, 'week').format('YYYY-MM-DD 00:00:00'),
      end: moment().format('YYYY-MM-DD 23:59:59'),
    },
    data: [],
    totalPages: 0,
    dataLoading: false,
    limit: 20,
    offset: 0,
    fileDataLoading: false,
    orderBy: 'views',
    sort: 'desc',
  },
  allPhrases: {
    timeFrame: {
      start: moment().subtract(1, 'week').format('YYYY-MM-DD 00:00:00'),
      end: moment().format('YYYY-MM-DD 23:59:59'),
    },
    data: [],
    totalPages: 0,
    dataLoading: false,
    limit: 20,
    offset: 0,
    fileDataLoading: false,
    orderBy: 'views',
    sort: 'desc',
  },
})

export default new Vuex.Store({
  state: getInitialState(),
  mutations: {
    setEmptyResultsData(state: any, data: PhrasesWithEmptyResults): void {
      state.emptyResults.data = data.data
      state.emptyResults.totalPages = Math.ceil(data.total / state.emptyResults.limit)
    },
    setEmptyResultsDataLoading(state: any, loading: boolean): void {
      state.emptyResults.dataLoading = loading
    },
    setEmptyResultsFileDataLoading(state: any, loading: boolean): void {
      state.emptyResults.fileDataLoading = loading
    },
    setEmptyResultPage(state: any, page: number): void {
      state.emptyResults.offset = (page - 1) * state.emptyResults.limit
    },
    setEmptyResultOrderBy(state: any, orderBy: string): void {
      state.emptyResults.orderBy = orderBy
    },
    setEmptyResultSort(state: any, orderDirection: string): void {
      state.emptyResults.sort = orderDirection
    },
    setEmptyResultTimeFrame(state: any, timeFrame: ReportTimeFrame): void {
      state.emptyResults.timeFrame.start = moment(timeFrame.start, 'YYYY-MM-DD').format('YYYY-MM-DD 00:00:00')
      state.emptyResults.timeFrame.end = moment(timeFrame.end).format('YYYY-MM-DD 23:59:59')
    },
    setAllPhrasesTimeFrame(state: any, timeFrame: ReportTimeFrame): void {
      state.allPhrases.timeFrame.start = moment(timeFrame.start, 'YYYY-MM-DD').format('YYYY-MM-DD 00:00:00')
      state.allPhrases.timeFrame.end = moment(timeFrame.end).format('YYYY-MM-DD 23:59:59')
    },
    setAllPhrasesDataLoading(state: any, loading: boolean): void {
      state.allPhrases.dataLoading = loading
    },
    setAllPhrasesFileDataLoading(state: any, loading: boolean): void {
      state.allPhrases.fileDataLoading = loading
    },
    setAllPhrasesData(state: any, data: any): void {
      state.allPhrases.data = data.data
      state.allPhrases.totalPages = Math.ceil(data.total / state.allPhrases.limit)
    },
    setAllPhrasesPage(state: any, page: number): void {
      state.allPhrases.offset = (page - 1) * state.allPhrases.limit
    },
    setAllPhrasesOrderBy(state: any, orderBy: string): void {
      state.allPhrases.orderBy = orderBy
    },
    setAllPhrasesSort(state: any, orderDirection: string): void {
      state.allPhrases.sort = orderDirection
    },
  },
  actions: {
    getAllPhrasesData(context) {
      context.commit('setAllPhrasesDataLoading', true)
      SearchReportApiRequestHelper.getAllPhrasesReport(
        {
          ...context.state.allPhrases.timeFrame,
          offset: context.state.allPhrases.offset,
          limit: context.state.allPhrases.limit,
          orderBy: context.state.allPhrases.orderBy,
          sort: context.state.allPhrases.sort,
        },
      )
        .then((result: AxiosResponse<AllPhrasesReport>) => {
          context.commit('setAllPhrasesDataLoading', false)
          if (result.status !== 200) {
            Notifications.commit('addError', 'search.errorGettingSearchInstances')
            return
          }
          context.commit('setAllPhrasesData', result.data)
        })
        .catch(error => {
          context.commit('setAllPhrasesDataLoading', false)
          Notifications.commit('addPopupError', error)
        })
    },
    getAllPhrasesDataFile(context) {
      context.commit('setAllPhrasesFileDataLoading', true)
      const dateFormat = 'YYYY-MM-DD'
      const fileName = `phrases_${context.state.searchId}_${moment(context.state.allPhrases.timeFrame.start).format(dateFormat)}-${moment(context.state.allPhrases.timeFrame.end).format(dateFormat)}.csv`

      apiRequest
        .post(SearchReportsApiRequestPaths.GET_PHRASES_ALL_REPORT_TO_FILE,
          {
            start: context.state.allPhrases.timeFrame.start,
            end: context.state.allPhrases.timeFrame.end,
          })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }))
          const link = document.createElement('a')
          link.href = url
          link.download = fileName
          link.click()
          context.commit('setAllPhrasesFileDataLoading', false)
        })
        .catch(error => {
          Notifications.commit('addError', error, { root: true })
          context.commit('setAllPhrasesFileDataLoading', false)
        })
    },
    getEmptyResultsPhrasesData(context) {
      context.commit('setEmptyResultsDataLoading', true)
      SearchReportApiRequestHelper.getEmptyResultsPhrasesReport(
        {
          ...context.state.emptyResults.timeFrame,
          offset: context.state.emptyResults.offset,
          limit: context.state.emptyResults.limit,
          orderBy: context.state.emptyResults.orderBy,
          sort: context.state.emptyResults.sort,
        },
      )
        .then((result: AxiosResponse<PhrasesWithEmptyResults>) => {
          context.commit('setEmptyResultsDataLoading', false)
          if (result.status !== 200) {
            Notifications.commit('addError', 'search.errorGettingSearchInstances')
            return
          }
          context.commit('setEmptyResultsData', result.data)
        })
        .catch(error => {
          context.commit('setEmptyResultsDataLoading')
          Notifications.commit('addPopupError', error)
        })
    },
    getEmptyResultsPhrasesDataFile(context) {
      context.commit('setEmptyResultsFileDataLoading', true)
      const dateFormat = 'YYYY-MM-DD'
      const fileName = `phrasesWithNoResults_${context.state.searchId}_${moment(context.state.emptyResults.timeFrame.start).format(dateFormat)}-${moment(context.state.emptyResults.timeFrame.end).format(dateFormat)}.csv`

      apiRequest
        .post(SearchReportsApiRequestPaths.GET_PHRASES_EMPTY_RESULTS_REPORT_TO_FILE,
          {
            start: context.state.emptyResults.timeFrame.start,
            end: context.state.emptyResults.timeFrame.end,
          })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }))
          const link = document.createElement('a')
          link.href = url
          link.download = fileName
          link.click()
          context.commit('setEmptyResultsFileDataLoading', false)
        })
        .catch(error => {
          Notifications.commit('addError', error, { root: true })
          context.commit('setEmptyResultsFileDataLoading', false)
        })
    },
  },
})
