import { AxiosResponse } from 'axios'
import apiRequest from '@/apiRequest'
import SearchReportsApiRequestPaths from '@/store/searchV3Report/searchReportApiRequestsPaths'

export interface ReportTimeFrame {
  start: string;
  end: string;
}

export interface CountForData {
  count: number
  data: string
}

export interface ReportTimeFrameOrderedWithLimit {
  start: string
  end: string
  limit: number
  offset: number
  orderBy: string
  sort: string
}

export interface GeneralPerformanceData {
  periodStart: string
  views: number,
  clicks: number,
  ctr: number,
  salesCount: number,
  salesUnitsSold: number,
  salesTotal: number,
  salesConversion: number
}

export interface PhrasesWithEmptyResults {
  total: number,
  data: {
    phrase: string
    views: number
  }[],
}
export interface AllPhrasesReport {
  total: number,
  data: {
    phrase: string
    views: number
    clicks: number
    ctr: string
    sales_count: number
    sales_units_sold: number
    sales_total: string
    sales_conversion: string
  }[],
}

export interface PhraseConversions {
  phrase: string
  conversionAmount: number
  conversionCount: number
  conversionProductsCount: number
}

export class SearchReportApiRequestHelper {
  static async getAllSearchesCountPerDay(timeFrame: ReportTimeFrame): Promise<AxiosResponse<Array<CountForData>>> {
    return apiRequest.post<Array<CountForData>>(SearchReportsApiRequestPaths.GET_SEARCHES_COUNT_PER_DAY, timeFrame)
  }

  static async getSearchesCountPerInstance(timeFrame: ReportTimeFrame): Promise<AxiosResponse<Array<CountForData>>> {
    return apiRequest.post<Array<CountForData>>(SearchReportsApiRequestPaths.GET_SEARCH_COUNT_PET_INSTANCES, timeFrame)
  }

  static async getPopularPhrases(timeFrame: ReportTimeFrame): Promise<AxiosResponse<Array<CountForData>>> {
    return apiRequest.post<Array<CountForData>>(SearchReportsApiRequestPaths.GET_PHRASES_POPULAR, timeFrame)
  }

  static async getEmptyResultsPhrases(timeFrame: ReportTimeFrame): Promise<AxiosResponse<Array<CountForData>>> {
    return apiRequest.post<Array<CountForData>>(SearchReportsApiRequestPaths.GET_PHRASES_EMPTY_RESULTS, timeFrame)
  }

  static async getMostClickedPhrases(timeFrame: ReportTimeFrame): Promise<AxiosResponse<Array<CountForData>>> {
    return apiRequest.post<Array<CountForData>>(SearchReportsApiRequestPaths.GET_PHRASES_MOST_CLICKED, timeFrame)
  }

  static async getPhrasesWithBestCountConversion(timeFrame: ReportTimeFrame): Promise<AxiosResponse<Array<CountForData>>> {
    return apiRequest.post<Array<CountForData>>(SearchReportsApiRequestPaths.GET_PHRASES_CONVERSION_BY_TRANSACTIONS_COUNT, timeFrame)
  }

  static async getPhrasesWithBestAmountConversion(timeFrame: ReportTimeFrame): Promise<AxiosResponse<Array<CountForData>>> {
    return apiRequest.post<Array<CountForData>>(SearchReportsApiRequestPaths.GET_PHRASES_CONVERSION_BY_TRANSACTIONS_AMOUNT, timeFrame)
  }

  static async getSearchesClicksCountPerDay(timeFrame: ReportTimeFrame): Promise<AxiosResponse<Array<CountForData>>> {
    return apiRequest.post<Array<CountForData>>(SearchReportsApiRequestPaths.GET_SEARCHES_CLICKS_COUNT_PER_DAY, timeFrame)
  }

  static getSearchesClickThroughRatePerDay(timeFrame: ReportTimeFrame): Promise<AxiosResponse<Array<CountForData>>> {
    return apiRequest.post<Array<CountForData>>(SearchReportsApiRequestPaths.GET_SEARCHES_CTR_PER_DAY, timeFrame)
  }

  static getSearchesTransactionCountPerDay(timeFrame: ReportTimeFrame): Promise<AxiosResponse<Array<CountForData>>> {
    return apiRequest.post<Array<CountForData>>(SearchReportsApiRequestPaths.GET_SEARCHES_TRANSACTIONS_COUNT_PER_DAY, timeFrame)
  }

  static getSearchesSalesAmountPerDay(timeFrame: ReportTimeFrame): Promise<AxiosResponse<Array<CountForData>>> {
    return apiRequest.post<Array<CountForData>>(SearchReportsApiRequestPaths.GET_SEARCHES_SALES_AMOUNT_PER_DAY, timeFrame)
  }

  static getSearchesConversionPercentPerDay(timeFrame: ReportTimeFrame): Promise<AxiosResponse<Array<CountForData>>> {
    return apiRequest.post<Array<CountForData>>(SearchReportsApiRequestPaths.GET_SEARCHES_CONVERSION_PERCENT_PER_DAY, timeFrame)
  }

  static getSearchesGeneralPerformancePerDay(timeFrame: ReportTimeFrame): Promise<AxiosResponse<Array<GeneralPerformanceData>>> {
    return apiRequest.post<Array<GeneralPerformanceData>>(SearchReportsApiRequestPaths.GET_SEARCHES_GENERAL_PERFORMANCE_PER_DAY, timeFrame)
  }

  static getAllPhrasesReport(timeFrame: ReportTimeFrame): Promise<AxiosResponse<AllPhrasesReport>> {
    return apiRequest.post<AllPhrasesReport>(SearchReportsApiRequestPaths.GET_PHRASES_ALL_REPORT, timeFrame)
  }

  static getEmptyResultsPhrasesReport(request: ReportTimeFrameOrderedWithLimit): Promise<AxiosResponse<PhrasesWithEmptyResults>> {
    return apiRequest.post<PhrasesWithEmptyResults>(SearchReportsApiRequestPaths.GET_PHRASES_EMPTY_RESULTS_REPORT, request)
  }
}
