export default class SearchReportsApiRequestPaths {
  static readonly GET_SEARCHES_COUNT_PER_DAY = 'search/v3/reports/searchesByDay'

  static readonly GET_SEARCH_COUNT_PET_INSTANCES = 'search/v3/reports/searchesPerInstance'

  static readonly GET_PHRASES_POPULAR = 'search/v3/reports/popularPhrases'

  static readonly GET_PHRASES_EMPTY_RESULTS = 'search/v3/reports/zeroSearchPhrases'

  static readonly GET_PHRASES_MOST_CLICKED = 'search/v3/reports/mostClickedPhrases'

  static readonly GET_PHRASES_CONVERSION_BY_TRANSACTIONS_COUNT = 'search/v3/reports/phrasesWithMostSoldProducts'

  static readonly GET_PHRASES_CONVERSION_BY_TRANSACTIONS_AMOUNT = 'search/v3/reports/phrasesWithHighestSalesValue'

  static readonly GET_SEARCHES_CLICKS_COUNT_PER_DAY = 'search/v3/reports/clicksByDay'

  static readonly GET_SEARCHES_CTR_PER_DAY = 'search/v3/reports/ctrByDay'

  static readonly GET_SEARCHES_TRANSACTIONS_COUNT_PER_DAY = 'search/v3/reports/transactionsCountByDay'

  static readonly GET_SEARCHES_SALES_AMOUNT_PER_DAY = 'search/v3/reports/salesAmountByDay'

  static readonly GET_SEARCHES_CONVERSION_PERCENT_PER_DAY = 'search/v3/reports/conversionByDay'

  static readonly GET_SEARCHES_GENERAL_PERFORMANCE_PER_DAY = 'search/v3/reports/generalPerformance'

  static readonly GET_PHRASES_ALL_REPORT = 'search/v3/reports/phrases'

  static readonly GET_PHRASES_ALL_REPORT_TO_FILE = 'search/v3/reports/phrasesFile'

  static readonly GET_PHRASES_EMPTY_RESULTS_REPORT = 'search/v3/reports/phrasesWithNoResults'

  static readonly GET_PHRASES_EMPTY_RESULTS_REPORT_TO_FILE = 'search/v3/reports/phrasesWithNoResultsFile'
}
